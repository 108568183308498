import { clsx } from '@alexis/helpers/clsx';
import { dateApiFormat, enMonthLabels, enShortDayLabels } from '@alexis/helpers/date';
import { translateNumber } from '@alexis/helpers/translation';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Skeleton from '@components/Skeleton';

import { rearrangeDayLabels } from '@helpers/date';

import { getLocaleState, getTranslationsState, getCurrencyState } from '@redux/selectors';

import { FareCalendarType } from '@wegoTypes/flights/metasearch/calendar';

import styles from './FareCalendar.module.scss';

interface FareCalendarProps {
  className?: string;
  firstDayOfWeek?: number;
  limitFromMilliseconds?: number;
  limitTillMilliseconds?: number;
  month: number;
  onDateSelected(value: Date): void;
  publicHolidayMap?: PublicHolidayMap;
  selectedFromDateMilliseconds?: number;
  selectedToDateMilliseconds?: number;
  year: number;
  calendarFares: { [date: string]: { amount: number } } | undefined;
  isLoadingFares: boolean;
  isShowFareCalendarVariantA: boolean;
  expensiveThreshold: number | undefined;
  cheapThreshold: number | undefined;
  passengerCount: number;
}

const FareCalendar: React.FC<FareCalendarProps> = ({
  className,
  firstDayOfWeek,
  limitFromMilliseconds,
  limitTillMilliseconds,
  month,
  onDateSelected,
  publicHolidayMap,
  selectedFromDateMilliseconds,
  selectedToDateMilliseconds,
  year,
  calendarFares,
  isLoadingFares,
  isShowFareCalendarVariantA,
  expensiveThreshold,
  cheapThreshold,
  passengerCount,
}) => {
  const locale = useSelector(getLocaleState);
  const translations = useSelector(getTranslationsState);
  const currency = useSelector(getCurrencyState);

  const dayLabels = translations.mini_weekdays as Array<string>;
  const monthLabels = translations.long_months as Array<string>;

  const defaultDayLabels = useMemo(
    () => rearrangeDayLabels(dayLabels || enShortDayLabels, firstDayOfWeek),
    [dayLabels, firstDayOfWeek],
  );

  const priceDecimalPlaces = useMemo(() => {
    if (!!calendarFares) {
      const maxPrice = Math.max(
        ...Object.keys(calendarFares).map((date) => calendarFares[date].amount),
      );

      const maxTotalPrice = maxPrice * passengerCount;

      if (maxTotalPrice >= 1000000) {
        return 2;
      } else if (maxTotalPrice >= 10000) {
        return 1;
      }

      return 0;
    }
    return 0;
  }, [calendarFares, passengerCount]);

  const getPriceForDisplay = useCallback(
    (dateFareAmount: number) => {
      const totalDateFare = dateFareAmount * passengerCount;
      const divisor = priceDecimalPlaces === 2 ? 1000000 : priceDecimalPlaces === 1 ? 1000 : 1;
      const amount = totalDateFare / divisor;

      let noOfDecimals = currency.numberOfDecimals || 0;

      if (priceDecimalPlaces) {
        const numberOfDigits = amount.toString().split('.')[0]?.length || 0;

        noOfDecimals = numberOfDigits > 2 ? 0 : numberOfDigits > 1 ? 1 : 2;
      }

      const amountToDisplay = Number(amount).toLocaleString('en-US', {
        maximumFractionDigits: noOfDecimals,
      });

      let unit = '';

      if (locale === 'fa') {
        unit = priceDecimalPlaces === 2 ? 'م' : priceDecimalPlaces === 1 ? 'ه' : '';
      } else {
        unit = priceDecimalPlaces === 2 ? 'M' : priceDecimalPlaces === 1 ? 'k' : '';
      }

      let symbol = currency.symbol.includes('$') ? '$' : '';

      return `${symbol}${translateNumber(amountToDisplay, locale === 'fa')}${
        !!unit ? ' ' : ''
      }${unit}`;
    },
    [priceDecimalPlaces, currency.numberOfDecimals, currency.symbol, locale, passengerCount],
  );

  const days = useMemo<Array<JSX.Element>>(() => {
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const lastDayOfMonth = new Date(year, month, 0, 23, 59, 59);
    const todayDateMilliseconds = new Date().setHours(0, 0, 0, 0);

    const days: Array<JSX.Element> = [];

    for (let n = 1; n < 43; n++) {
      const dayIndexOffset = firstDayOfWeek === 7 ? 1 : firstDayOfWeek === 6 ? 2 : 0;
      const dayIndex = (n - dayIndexOffset) % 7;
      const day = firstDayOfMonth.getDay();
      const firstDayOfMonthMilliseconds = firstDayOfMonth.getTime();
      const lastDayOfMonthMilliseconds = lastDayOfMonth.getTime();

      if (day === dayIndex && firstDayOfMonthMilliseconds <= lastDayOfMonthMilliseconds) {
        const date = firstDayOfMonth.getDate();
        const isPublicHoliday =
          (!!publicHolidayMap && !!publicHolidayMap[dateApiFormat(firstDayOfMonth)]) || false;
        const isToday = firstDayOfMonthMilliseconds === todayDateMilliseconds;
        const isSelectedFromDate =
          selectedFromDateMilliseconds &&
          selectedFromDateMilliseconds === firstDayOfMonthMilliseconds;
        const isInSelectedDateRange =
          selectedFromDateMilliseconds &&
          selectedToDateMilliseconds &&
          firstDayOfMonthMilliseconds > selectedFromDateMilliseconds &&
          firstDayOfMonthMilliseconds < selectedToDateMilliseconds;
        const isSelectedToDate =
          selectedToDateMilliseconds && selectedToDateMilliseconds === firstDayOfMonthMilliseconds;
        const isDisabled =
          (limitFromMilliseconds === undefined &&
            firstDayOfMonthMilliseconds < todayDateMilliseconds) ||
          (limitFromMilliseconds !== undefined &&
            firstDayOfMonthMilliseconds < limitFromMilliseconds) ||
          (limitTillMilliseconds !== undefined &&
            firstDayOfMonthMilliseconds > limitTillMilliseconds);

        const dateFare = calendarFares?.[dateApiFormat(new Date(firstDayOfMonthMilliseconds))];

        let priceType: FareCalendarType | undefined;
        let isStartOrEndDate = !!isSelectedFromDate || !!isSelectedToDate;

        let price;

        if (!!dateFare?.amount) {
          price = getPriceForDisplay(dateFare.amount);

          if (!!cheapThreshold && !!expensiveThreshold) {
            priceType =
              dateFare.amount <= cheapThreshold
                ? 'CHEAP'
                : dateFare.amount >= expensiveThreshold
                ? 'EXPENSIVE'
                : 'NORMAL';
          }
        }

        days.push(
          <div
            data-index={n}
            data-testid={dateApiFormat(new Date(firstDayOfMonthMilliseconds))}
            data-pw={`${
              !!isInSelectedDateRange || !!isSelectedFromDate || !!isSelectedToDate
                ? 'datePicker_dateInSelectedRange'
                : isDisabled
                ? 'datePicker_unavailableDate'
                : 'datePicker_availableDate'
            }`}
            key={n}
            className={clsx(
              !!isSelectedFromDate && styles.fromDate,
              !!isSelectedToDate && styles.toDate,
              !!selectedToDateMilliseconds && styles.hasToDate,
              !!isInSelectedDateRange && styles.inRange,
            )}
            onClick={
              !isDisabled ? () => onDateSelected(new Date(firstDayOfMonthMilliseconds)) : undefined
            }
          >
            <div
              data-pw={`${isPublicHoliday ? 'datePicker_publicHoliday' : ''}`}
              className={clsx(
                styles.date,
                !!isSelectedFromDate && styles.start,
                !!isSelectedToDate && styles.end,
                isPublicHoliday && styles.publicHoliday,
                isToday && styles.today,
                !isDisabled && styles.selectable,
                !!isDisabled && styles.disabled,
                !isStartOrEndDate && priceType === 'CHEAP' && styles.cheapDate,
                !isStartOrEndDate && priceType === 'EXPENSIVE' && styles.expensiveDate,
                !isStartOrEndDate && priceType === 'NORMAL' && styles.normalDate,
                isShowFareCalendarVariantA ? styles.variantA : styles.variantB,
              )}
            >
              <span className={styles.dateNumber}>{translateNumber(date, locale === 'fa')}</span>

              {/* Fare for each date */}
              <div className={styles.priceConatiner}>
                {isLoadingFares && !isDisabled && (
                  <Skeleton width={32} height={8} borderRadius={4} />
                )}

                {!isLoadingFares && !isDisabled && !!price ? price : null}
              </div>
            </div>
          </div>,
        );
        firstDayOfMonth.setDate(date + 1);
      } else {
        if ((n === 29 || n === 36) && firstDayOfMonthMilliseconds >= lastDayOfMonthMilliseconds) {
          break;
        }
        days.push(<div key={n} data-index={n}></div>);
      }
    }
    return days;
  }, [
    locale,
    month,
    year,
    firstDayOfWeek,
    limitFromMilliseconds,
    limitTillMilliseconds,
    publicHolidayMap,
    selectedFromDateMilliseconds,
    selectedToDateMilliseconds,
    calendarFares,
    isLoadingFares,
    getPriceForDisplay,
  ]);

  return (
    <div data-testid='calendar-container' className={clsx(styles.container, className)}>
      <div className={styles.month}>
        {monthLabels ? monthLabels[month - 1] : enMonthLabels[month - 1]}{' '}
        {translateNumber(year, locale === 'fa')}
      </div>
      <div className={styles.dayLabels}>
        {defaultDayLabels.map((defaultDayLabel, index) => (
          <div key={index}>{defaultDayLabel}</div>
        ))}
      </div>

      <div className={styles.days}>{days}</div>
    </div>
  );
};

export default FareCalendar;
