import React, { forwardRef, type PropsWithChildren, useCallback } from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import ClearIcon from '../icons/close.svg';
import styles from '../styles/components/InputSelector.module.scss';

interface InputSelectorProps {
  className?: string;
  hasError?: boolean;
  id?: string;
  isFlightInbound?: boolean;
  isFlightProduct?: boolean;
  isFocus: boolean;
  isValidated: boolean;
  listContainerId?: string;
  name?: string;
  onChange(value: string): void;
  onClear?(): void;
  onFocus(): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement>): void;
  placeholder: string;
  style?: React.CSSProperties;
  value?: string;
  dataPw?: string;
}

const InputSelector = forwardRef<HTMLInputElement, PropsWithChildren<InputSelectorProps>>(
  (
    {
      children,
      className,
      hasError,
      id,
      isFlightInbound,
      isFlightProduct,
      isFocus,
      isValidated,
      listContainerId,
      name,
      onChange,
      onClear,
      onFocus,
      onKeyDown,
      placeholder,
      style,
      value,
      dataPw,
    },
    ref,
  ) => {
    const handleInputClick = useCallback<(event: React.MouseEvent<HTMLInputElement>) => void>(
      (event: React.MouseEvent<HTMLInputElement>) => {
        if (isFocus) {
          event.stopPropagation();
        }
        onFocus();
      },
      [onFocus, isFocus],
    );

    const handleClearButtonClick = useCallback<
      (event: React.MouseEvent<HTMLButtonElement>) => void
    >(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        if (!!onClear) {
          onClear();
        }
      },
      [onClear],
    );

    const defaultInputId = 'inputId';

    return (
      <div
        data-testid='input-selector'
        className={clsx(
          styles.container,
          className,
          isFocus && styles.focus,
          isValidated && styles.validated,
          !!hasError && styles.error,
        )}
        style={style}
      >
        <div className={clsx(styles.inputContainer, isFlightInbound && styles.flightInbound)}>
          <input
            autoComplete='off'
            id={id ?? defaultInputId}
            inputMode='text'
            name={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            onClick={handleInputClick}
            onKeyDown={onKeyDown}
            ref={ref}
            spellCheck={false}
            type='text'
            value={value}
          />
          <label htmlFor={id ?? defaultInputId}>{placeholder}</label>

          {!!value && !!onClear && isFocus ? (
            <button type='button' onClick={handleClearButtonClick}>
              <ClearIcon className={styles.icon} />
            </button>
          ) : null}

          {isFlightProduct ? <div className={styles.box}></div> : null}
        </div>

        {isFocus ? (
          <div
            data-pw={dataPw}
            id={listContainerId}
            className={styles.children}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        ) : null}
      </div>
    );
  },
);

export default InputSelector;
