import { googleOneTapSignIn } from '@alexis/helpers/authentications';
import { retrieveWegoUserInformation, wegoRefreshSignIn } from '@alexis/helpers/authentications';
import { getCookie } from '@alexis/helpers/cookie';
import { getGMTOffsetFromDate, toISOStringWithTimezone } from '@alexis/helpers/date';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import { genzoTrack, genzoTrackNewSession, initializeGenzo } from '@alexis/helpers/genzo';
import { generateCUID, initializeIdentity } from '@alexis/helpers/identity';
import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import { locales } from '@alexis/helpers/site';
import { BookingPageProvider } from '@pages/FlightBookingPage/context/BookingPageContext';
import PreferencesPreferredPaymentMethods from '@pages/PreferredPaymentMethods';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import { getCurrencyConfig } from '@wego/alexis/helpers/currency';
import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import AuthenticatedRoutes from '@components/AuthenticatedRoutes';
import ContentErrorBoundary from '@components/ErrorBoundary/ContentError/ContentErrorBoundary';

import { GOOGLE_CLIENT_ID, WEGO_CLIENT_ID } from '@constants/env';

import { getClientSideUserCentricConnect } from '@helpers/experiment';
import { emptyFlightRecentSearchForComparing } from '@helpers/flight';
import { loadForterScript } from '@helpers/forter';
import { cleanHomepageData } from '@helpers/home';
import { isWegoShopcashEnabled } from '@helpers/shopcash';

import { useGetCurrencyDisplayFormat } from '@hooks/api/currency/useGetCurrencyDisplayFormat';
import { useGetSupportedPaymentTypes } from '@hooks/api/flights/useGetSupportedPaymentTypes';
import { useGetHomepage } from '@hooks/api/places/useGetHomepage';
import { useGetNearestPlaces } from '@hooks/api/places/useGetNearestPlaces';
import { useIsAuthNew } from '@hooks/useIsAuthNew';

import { changeCurrency } from '@redux/actions/currencyActions';

import type { User } from '@wegoTypes/authentication';
import { Currency, CurrencyCodes } from '@wegoTypes/currency';
import { HotelMetaSearch } from '@wegoTypes/hotels/hotelMetaSearch';
import type { Translations as TTranslations } from '@wegoTypes/translations';

import { getUserCentricConnect } from '../apis/abTest';
import ExchangeRate from '../components/ExchangeRate';
import FlightSearchLayout from '../components/flights/FlightSearchLayout';
import Home from '../components/home/Home';
import HotelSearchLayout from '../components/hotels/HotelSearchLayout';
import IsRtl from '../components/IsRtl';
import Layout from '../components/Layout';
import ShopCashUser from '../components/ShopCashUser';
import Translations from '../components/Translations';
import {
  HERO_IMAGE_ID_LOCAL_STORAGE_KEY,
  SESSION_ID_LOCAL_STORAGE_KEY,
} from '../constants/localStorage';
import { GlobalContext } from '../context/GlobalContext';
import { isGoogleBot } from '../helpers/bots';
import { geolocation } from '../helpers/geolocation';
import { cloudinaryImageTransformations } from '../helpers/imageTransformations';
import { flightsPaths, hotelsPaths, preferencesPaths } from '../helpers/path';
import { injectSiftSnippet } from '../helpers/siftFraudDetection';
import {
  getWebEngageAnonymousId,
  webEngageSetUserAttribute,
  webEngageTrackLogin,
} from '../helpers/webEngage';
import { changeUser, resetUser } from '../redux/actions/userActions';
import domainBasedApiBaseUrlReducer from '../redux/reducers/domainBasedApiBaseUrl';
import hotelMetaSearchReducer from '../redux/reducers/hotelMetaSearch';
import priceOptionsReducer from '../redux/reducers/priceOptions';
import shopCashUserReducer from '../redux/reducers/shopCashUser';
import triggerSearchCounterReducer from '../redux/reducers/triggerSearchCounter';
import userReducer from '../redux/reducers/user';
import {
  getCurrencyState,
  getCurrentSiteState,
  getDomainBasedApiBaseUrlState,
  getExchangeRateState,
  getHotelMetaSearchState,
  getIsRtlState,
  getLocaleState,
  getPriceOptionsState,
  getShopCashUserState,
  getTranslationsState,
  getTriggerSearchCounterState,
  getUserState,
} from '../redux/selectors';
import FlightsPathRoute from './FlightsPathRoute';
import HotelsPathRoute from './HotelsPathRoute';
import PreferencesPathRoute from './PreferencesPathRoute';

const FlightSearchResult = lazy(
  () => import(/* webpackChunkName: "FlightSearchResult" */ '@pages/FlightSearchResult'),
);

const TripDetailStandalone = lazy(
  () =>
    import(
      /* webpackChunkName: "TripDetailStandalone" */ '@components/flights/TripDetailStandalone'
    ),
);

const FlightFareComparison = lazy(
  () => import(/* webpackChunkName: "FlightFareComparison" */ '@pages/FlightFareComparison'),
);

const FlightExternalHandoff = lazy(
  () =>
    import(
      /* webpackChunkName: "FlightExternalHandoff" */ '@components/flightBooking/FlightExternalHandoff'
    ),
);

const FlightBooking = lazy(
  () => import(/* webpackChunkName: "FlightBooking" */ '@pages/FlightBookingPage'),
);

const PaymentVerification = lazy(
  () => import(/* webpackChunkName: "PaymentVerification" */ '@pages/PaymentVerification'),
);

const FlightBookingConfirmation = lazy(
  () =>
    import(/* webpackChunkName: "FlightBookingConfirmation" */ '@pages/FlightBookingConfirmation'),
);

const FlightBookingHistory = lazy(
  () => import(/* webpackChunkName: "FlightBookingHistory" */ '@pages/FlightBookingHistory'),
);

const HotelBookingHistory = lazy(
  () => import(/* webpackChunkName: "HotelBookingHistory" */ '@pages/HotelBookingHistory'),
);

const FlightBookingHistoryDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "FlightBookingHistoryDetails" */ '@pages/FlightBookingHistoryDetails'
    ),
);

const HotelBooking = lazy(
  () => import(/* webpackChunkName: "HotelBooking" */ '@pages/HotelBooking'),
);

const HotelBookingConfirmation = lazy(
  () =>
    import(/* webpackChunkName: "HotelBookingConfirmation" */ '@pages/HotelBookingConfirmation'),
);

const HotelBookingReview = lazy(
  () => import(/* webpackChunkName: "HotelReview" */ '@pages/HotelBookingReview'),
);

const HotelDetail = lazy(() => import(/* webpackChunkName: "HotelDetail" */ '@pages/HotelDetail'));

const HotelSearchResult = lazy(
  () => import(/* webpackChunkName: "HotelSearchResult" */ '@pages/HotelSearchResult'),
);

const ContactUs = lazy(() => import(/* webpackChunkName: "ContactUs" */ '@pages/ContactUs'));

const MyWallet = lazy(() => import(/* webpackChunkName: "MyWallet" */ '@components/MyWallet'));

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ '@pages/Dashboard'));

loadForterScript();

interface RoutesProps {
  onInjectReducers(asyncReducers: { [key: string]: any }): void;
}

const Routes$: React.FC<RoutesProps> = ({ onInjectReducers }) => {
  onInjectReducers({
    domainBasedApiBaseUrl: domainBasedApiBaseUrlReducer,
    hotelMetaSearch: hotelMetaSearchReducer,
    priceOptions: priceOptionsReducer,
    shopCashUser: shopCashUserReducer,
    triggerSearchCounter: triggerSearchCounterReducer,
    user: userReducer,
  });

  //#region Redux Selectors
  const apiBaseUrl: string = API_BASE_URL;
  const currency: Currency = useSelector(getCurrencyState);
  const currentSite: CurrentSite = useSelector(getCurrentSiteState);
  const domainBasedApiBaseUrl: string = useSelector(getDomainBasedApiBaseUrlState);
  const exchangeRate: ExchangeRate = useSelector(getExchangeRateState);
  const hotelMetaSearch: HotelMetaSearch = useSelector(getHotelMetaSearchState);
  const isRtl: boolean = useSelector(getIsRtlState);
  const locale: string = useSelector(getLocaleState);
  const priceOptions: PriceOptions = useSelector(getPriceOptionsState);
  const translations: TTranslations = useSelector(getTranslationsState);
  const triggerSearchCounter: number = useSelector(getTriggerSearchCounterState);
  const user: User | null = useSelector(getUserState);
  const shopCashUser: ShopCashUser | null = useSelector(getShopCashUserState);
  //#endregion

  const [searchParams] = useSearchParams();
  const currencySearchParam = searchParams.get('wego_currency') || searchParams.get('currency');

  const hostname = window.location.hostname;
  const gtmAuth: string =
    isDevelopmentEnvironment(hostname) || isStagingEnvironment(hostname)
      ? 'obU596jZkQ8pXq1df8v0OA'
      : 'JhTkGQKxflcbelVyldTavg';
  const gtmPreview: string =
    isDevelopmentEnvironment(hostname) || isStagingEnvironment(hostname) ? 'env-5' : 'env-2';

  const [geolocationCoordinates, setGeolocationCoordinates] = useState<GeolocationCoordinates>();

  const [userCentricCoreConfigs, setUserCentricCoreConfigs] = useState<Array<CoreConfig>>([]);

  const [clientSideUserCentricCoreConfigs, setClientSideUserCentricCoreConfigs] = useState<
    Array<CoreConfig>
  >([]);

  const [wegoAnalyticsClientId, setWegoAnalyticsClientId] = useState<string>();
  const [wegoAnalyticsClientSessionId, setWegoAnalyticsClientSessionId] = useState<string>();
  const [lastTrackedWegoAnalyticsClientSessionId, setLastTrackedWegoAnalyticsClientSessionId] =
    useState<string>();

  const { usedNewLogin } = useIsAuthNew();
  const [webEngageAnonymousId, setWebEngageAnonymousId] = useState<string>();

  const heroImageBaseUrl: string = cloudinaryImageTransformations(
    `https://assets.wego.com/image/upload/v1597920831/web/hero_images/${currentSite.countryCode.toLowerCase()}_`,
    'c_fill',
    'fl_lossy',
    'q_auto:best',
    'f_auto',
    'w_2560',
  );

  const campaign = useMemo<Campaign | undefined>(() => {
    const campaigns = [
      {
        name: 'saudi_tourism',
        startDate: '2022-10-03',
        endDate: '2022-12-31',
        countryCodes: ['EG', 'QA'],
        hasLogo: true,
        hasLogoInArabic: true,
        cloudinaryVersion: 'v1597920829',
      },
      {
        name: 'qatar_tourism',
        startDate: '2022-12-07',
        endDate: '2023-01-31',
        countryCodes: ['AE', 'BH', 'KW', 'OM', 'SA'],
        hasLogo: true,
        hasLogoInArabic: false,
        cloudinaryVersion: 'v1671678834',
      },
    ];

    const currentTime = new Date().getTime();

    return campaigns.find((campaign) => {
      const campaignStartDate = new Date(campaign.startDate);

      const campaignEndDate = new Date(campaign.endDate);
      campaignEndDate.setDate(campaignEndDate.getDate() + 1);

      if (
        campaign.countryCodes.some((countryCode) => countryCode === currentSite.countryCode) &&
        currentTime > campaignStartDate.getTime() &&
        currentTime < campaignEndDate.getTime()
      ) {
        return true;
      }
      return false;
    });
  }, [currentSite]);

  const campaignLogoImageUrl = useMemo<string | undefined>(() => {
    if (!!campaign && campaign.hasLogo) {
      return cloudinaryImageTransformations(
        `https://assets.wego.com/image/upload/${campaign.cloudinaryVersion}/web/nto_images/${
          campaign.name
        }_logo${campaign.hasLogoInArabic && locale === 'ar' ? '_ar' : ''}`,
        'c_fill',
        'fl_lossy',
        'q_auto:eco',
        'f_auto',
        'h_200',
      );
    }
    return undefined;
  }, [campaign, locale]);

  const getCampaignHeroImageBaseUrl = useCallback<(cloudinaryVersion: string) => string>(
    (cloudinaryVersion: string) => {
      return cloudinaryImageTransformations(
        `https://assets.wego.com/image/upload/${cloudinaryVersion}/web/nto_images`,
        'c_fill',
        'fl_lossy',
        'q_auto:best',
        'f_auto',
        'w_2560',
      );
    },
    [],
  );

  const heroImageId = useMemo<number>(() => {
    const updateHeroImageForNextLoad = (
      heroImageIdLocalStorageKey: string,
      heroImageBaseUrl: string,
      homeImageId: number,
    ): void => {
      const incrementHeroImageId: number = homeImageId + 1;
      const heroImageUrl: string = `${heroImageBaseUrl}${incrementHeroImageId}.jpg`;

      const image = new window.Image();

      image.onload = () => {
        localStorageSave(heroImageIdLocalStorageKey, incrementHeroImageId);
      };

      image.onerror = () => {
        localStorageSave(heroImageIdLocalStorageKey, 1);
      };

      image.src = heroImageUrl;
    };

    if (!!campaign) {
      const campaignHeroImageIdLocalStorageKey: string = `${campaign.name}_heroImageId`;
      const campaignHeroImageId: number =
        localStorageGet<number>(campaignHeroImageIdLocalStorageKey) ?? 1;

      updateHeroImageForNextLoad(
        campaignHeroImageIdLocalStorageKey,
        `${getCampaignHeroImageBaseUrl(campaign.cloudinaryVersion)}/${campaign.name}_`,
        campaignHeroImageId,
      );

      return campaignHeroImageId;
    } else {
      const heroImageId: number = localStorageGet<number>(HERO_IMAGE_ID_LOCAL_STORAGE_KEY) ?? 1;

      updateHeroImageForNextLoad(HERO_IMAGE_ID_LOCAL_STORAGE_KEY, heroImageBaseUrl, heroImageId);

      return heroImageId;
    }
  }, [campaign]);

  const heroImageUrl = useMemo<string>(() => {
    if (!!campaign) {
      return `${getCampaignHeroImageBaseUrl(campaign.cloudinaryVersion)}/${
        campaign.name
      }_${heroImageId}.jpg`;
    }
    return `${heroImageBaseUrl}${heroImageId}.jpg`;
  }, [campaign, heroImageId]);

  const dispatch = useDispatch();

  // Get homepage
  const { data: homepage } = useGetHomepage({
    select: (data) => cleanHomepageData(data),
  });

  // inject sift fraud detection script
  useEffect(() => {
    if (!!wegoAnalyticsClientSessionId) {
      const userHash = !!user ? user.userHash : '';
      injectSiftSnippet(userHash, wegoAnalyticsClientSessionId);
    }
  }, [user, wegoAnalyticsClientSessionId]);

  // Get geolocation coordinates
  useEffect(() => {
    const initGeolocation = async (): Promise<void> => {
      try {
        const geolocationCoordinates = await geolocation();

        setGeolocationCoordinates(geolocationCoordinates);
      } catch (error) {}
    };

    initGeolocation();
  }, []);

  // Get nearest city based on IP
  const { data: nearestCityByIP } = useGetNearestPlaces(
    {
      isCity: true,
    },
    {
      select: (data) => data[0],
    },
  );

  // get supported payment types
  const { data: availablePaymentMethods } = useGetSupportedPaymentTypes();

  const { data: currencyDisplayFormat } = useGetCurrencyDisplayFormat();

  useEffect(() => {
    const selectedCurrency = currencySearchParam
      ? (getCurrencyConfig(currencySearchParam) as Currency)
      : currency;

    if (selectedCurrency && currencyDisplayFormat) {
      const fractionDigits =
        currencyDisplayFormat[selectedCurrency.code as CurrencyCodes]?.fractionDigits;

      if (selectedCurrency.numberOfDecimals !== fractionDigits) {
        selectedCurrency.numberOfDecimals = fractionDigits;
        dispatch(changeCurrency(selectedCurrency));
      }
    }
  }, [currencyDisplayFormat, currency.code, currencySearchParam]);

  // Initialize indentiy, tracking and cookie bot
  useEffect(() => {
    if (!isGoogleBot) {
      webEngageSetUserAttribute('time_zone', `GMT${getGMTOffsetFromDate(new Date())}`);
      webEngageSetUserAttribute('wego_country', currentSite.countryCode);

      initializeGenzo();

      const wegoAnalyticsClientIdCookie = getCookie('wego_analytics_client_id');
      setWegoAnalyticsClientId(wegoAnalyticsClientIdCookie);

      const wegoAnalyticsClientSessionIdCookie = getCookie('wego_analytics_client_session_id');
      setLastTrackedWegoAnalyticsClientSessionId(wegoAnalyticsClientSessionIdCookie);
      setWegoAnalyticsClientSessionId(wegoAnalyticsClientSessionIdCookie);

      const handleNewWegoClient = ({ detail }: CustomEventInit): void => {
        if (!!detail.wegoAnalyticsClientId) {
          setWegoAnalyticsClientId(detail.wegoAnalyticsClientId);
        }
      };
      document.addEventListener('newWegoClient', handleNewWegoClient);

      const handleNewWegoSession = ({ detail }: CustomEventInit): void => {
        if (!!detail.wegoAnalyticsClientSessionId) {
          setWegoAnalyticsClientSessionId(detail.wegoAnalyticsClientSessionId);
        }
      };
      document.addEventListener('newWegoSession', handleNewWegoSession);

      initializeIdentity();

      return () => {
        document.removeEventListener('newWegoClient', handleNewWegoClient);
        document.removeEventListener('newWegoSession', handleNewWegoSession);
      };
    }
  }, []);

  // Track web engage user location
  useEffect(() => {
    if (!isGoogleBot && !!nearestCityByIP) {
      webEngageSetUserAttribute('user_country_code', nearestCityByIP.countryCode);
      webEngageSetUserAttribute('user_city_code', nearestCityByIP.cityCode!);
      webEngageSetUserAttribute('cityName', nearestCityByIP.cityName!);
    }
  }, [nearestCityByIP]);

  // Get webEngageAnonymousId
  useEffect(() => {
    if (!webEngageAnonymousId) {
      const getWebengageAnonymousId = async (): Promise<void> => {
        try {
          const webEngageAnonymousId = await getWebEngageAnonymousId();
          setWebEngageAnonymousId(webEngageAnonymousId);
        } catch (error) {}
      };

      getWebengageAnonymousId();
    }
  }, []);

  // Track genzo new session
  useEffect(() => {
    if (
      !!wegoAnalyticsClientId &&
      !!wegoAnalyticsClientSessionId &&
      wegoAnalyticsClientSessionId !== lastTrackedWegoAnalyticsClientSessionId
    ) {
      genzoTrackNewSession(
        apiBaseUrl,
        'WEB_APP',
        currentSite.countryCode,
        locale,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        user?.userHash,
      );
      setLastTrackedWegoAnalyticsClientSessionId(wegoAnalyticsClientSessionId);
    }
  }, [wegoAnalyticsClientId, wegoAnalyticsClientSessionId]);

  const refreshUserAuthenticationToken = useCallback<(user: User) => Promise<void>>(
    async (user: User) => {
      if (usedNewLogin) {
        return;
      }

      try {
        const expireBy = await wegoRefreshSignIn(domainBasedApiBaseUrl);
        dispatch(changeUser({ ...user, expireBy }));
      } catch (error) {
        dispatch(resetUser());
      }
    },
    [usedNewLogin],
  );

  // Initialize google one tap sign in
  useEffect(() => {
    const initializeGoogleOneTapSignIn = async (): Promise<void> => {
      try {
        const user = await googleOneTapSignIn(
          domainBasedApiBaseUrl,
          GOOGLE_CLIENT_ID,
          WEGO_CLIENT_ID,
        );
        dispatch(changeUser(user));
        webEngageTrackLogin(user);
      } catch (error) {
        dispatch(resetUser());
      }
    };

    const checkUserAuthenticationTokenValidity = async (user: User): Promise<void> => {
      if (user.expireBy > Date.now()) {
        try {
          await retrieveWegoUserInformation(domainBasedApiBaseUrl);
        } catch (error) {
          await refreshUserAuthenticationToken(user);
        }
      } else {
        await refreshUserAuthenticationToken(user);
      }
    };

    if (user) {
      checkUserAuthenticationTokenValidity(user);
    } else {
      initializeGoogleOneTapSignIn();
    }
  }, []);

  // Revalidate login user when access token is expired
  useEffect(() => {
    const nowMilliseconds = Date.now();

    if (!!user && user.expireBy > nowMilliseconds && !usedNewLogin) {
      const wegorefreshTimeout = setTimeout(async (): Promise<void> => {
        await refreshUserAuthenticationToken(user);
      }, user.expireBy - nowMilliseconds);

      return () => {
        clearTimeout(wegorefreshTimeout);
      };
    }
  }, [user, usedNewLogin]);

  const trackExperimentsInGenzo = useCallback(
    async (coreConfigs: CoreConfig[]) => {
      const filteredCoreConfigs = coreConfigs.filter(
        (config) => config.experimentName && config.variantName,
      );

      if (filteredCoreConfigs.length === 0) {
        return;
      }

      const data: GenzoExperimentPayload = {
        id: generateCUID(),
        created_at: toISOStringWithTimezone(new Date()),
        client: {
          id: wegoAnalyticsClientId,
          session_id: wegoAnalyticsClientSessionId,
          user_agent: window.navigator.userAgent,
          user_hash: user?.userHash,
        },
        experiments: filteredCoreConfigs.map((config) => ({
          experiment: config.experimentName,
          variant: config.variantName,
        })),
      };

      return genzoTrack(`${apiBaseUrl}/genzo/v3/experiments/event`, data);
    },
    [wegoAnalyticsClientId, wegoAnalyticsClientSessionId, user?.userHash],
  );

  // Initiate user centric connect for A/B testing experiments and then Send A/B testing data to genzo
  useEffect(() => {
    if (!!wegoAnalyticsClientId && !!wegoAnalyticsClientSessionId) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      const getUserCentricAndSendToGenzo = async (cancelToken: CancelToken) => {
        try {
          const userCentric = await getUserCentricConnect(
            apiBaseUrl,
            wegoAnalyticsClientId,
            'DESKTOP',
            currentSite.countryCode,
            locale,
            'WEB_APP',
            'DESKTOP',
            currency.code,
            'coreConfigs',
            cancelToken,
          );

          const userCentricCoreConfig = userCentric.data.coreConfigs;

          setUserCentricCoreConfigs(userCentricCoreConfig);

          await trackExperimentsInGenzo(userCentricCoreConfig);
        } catch (e: unknown) {
          throw new Error('A/B testing API failed - dWeb');
        }
      };

      getUserCentricAndSendToGenzo(cancelTokenSource.token);

      return () => {
        cancelTokenSource.cancel('Component unmount.');
      };
    }
  }, [
    currency,
    currentSite,
    locale,
    trackExperimentsInGenzo,
    wegoAnalyticsClientId,
    wegoAnalyticsClientSessionId,
  ]);

  useEffect(() => {
    if (!!wegoAnalyticsClientId && !!wegoAnalyticsClientSessionId) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      const getClientSideUserCentricAndSendToGenzo = async () => {
        try {
          const clientSideCoreConfigs = getClientSideUserCentricConnect(
            wegoAnalyticsClientId,
            currentSite.countryCode,
          );

          setClientSideUserCentricCoreConfigs(clientSideCoreConfigs);

          await trackExperimentsInGenzo(clientSideCoreConfigs);
        } catch (e: unknown) {
          throw new Error('A/B testing API failed - dWeb');
        }
      };

      getClientSideUserCentricAndSendToGenzo();

      return () => {
        cancelTokenSource.cancel('Component unmount.');
      };
    }
  }, [currentSite, trackExperimentsInGenzo, wegoAnalyticsClientId, wegoAnalyticsClientSessionId]);

  // Save sessionId into local storage
  useEffect(() => {
    if (wegoAnalyticsClientSessionId) {
      const sessionIdInLocalStorage = localStorageGet<string>(SESSION_ID_LOCAL_STORAGE_KEY);

      if (!sessionIdInLocalStorage) {
        localStorageSave<string>(SESSION_ID_LOCAL_STORAGE_KEY, wegoAnalyticsClientSessionId);
      } else {
        if (wegoAnalyticsClientSessionId !== sessionIdInLocalStorage) {
          // empty flight recent searches if there is new sessionId
          emptyFlightRecentSearchForComparing();

          localStorageSave<string>(SESSION_ID_LOCAL_STORAGE_KEY, wegoAnalyticsClientSessionId);
        }
      }
    }
  }, [wegoAnalyticsClientSessionId]);

  const flightsPathRoutes = useCallback<
    (flightsPaths: Array<string>, localeParam: string | undefined) => Array<React.ReactElement>
  >(
    (flightsPaths: Array<string>, localeParam: string | undefined) => {
      return flightsPaths.map((flightsPath) => (
        <Route
          key={flightsPath}
          path={flightsPath}
          element={
            <FlightsPathRoute
              flightsParam={flightsPath}
              locale={locale}
              localeParam={localeParam}
              translations={translations}
            />
          }
        >
          <Route
            index
            element={
              <Home
                availablePaymentMethods={availablePaymentMethods || []}
                campaignLogoImageUrl={campaignLogoImageUrl}
                geolocationCoordinates={geolocationCoordinates}
                gtmAuth={gtmAuth}
                gtmPreview={gtmPreview}
                heroImageUrl={heroImageUrl}
                homepage={homepage}
                nearestCityByIP={nearestCityByIP}
                translations={translations}
                user={user}
                wegoAnalyticsClientId={wegoAnalyticsClientId}
                wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
              />
            }
          />

          <Route
            path='searches/trips/:tripId'
            element={
              <TripDetailStandalone
                localeParam={localeParam}
                gtmAuth={gtmAuth}
                gtmPreview={gtmPreview}
                clientId={wegoAnalyticsClientId}
                clientSessionId={wegoAnalyticsClientSessionId}
                paymentMethodsFromApi={availablePaymentMethods || []}
              />
            }
          />

          <Route
            path='searches/:leg/:cabinClass/:adultCount::childCount::infantCount'
            element={
              <ContentErrorBoundary isErrorReload>
                <FlightSearchLayout
                  availablePaymentMethods={availablePaymentMethods || []}
                  geolocationCoordinates={geolocationCoordinates}
                  gtmAuth={gtmAuth}
                  gtmPreview={gtmPreview}
                  homepage={homepage}
                  wegoAnalyticsClientId={wegoAnalyticsClientId}
                  wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                />
              </ContentErrorBoundary>
            }
          >
            <Route
              index
              element={
                <FlightSearchResult
                  availablePaymentMethods={availablePaymentMethods || []}
                  gtmAuth={gtmAuth}
                  gtmPreview={gtmPreview}
                  localeParam={localeParam}
                  nearestCityByIP={nearestCityByIP}
                  wegoAnalyticsClientId={wegoAnalyticsClientId}
                  wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                />
              }
            />
          </Route>

          {/* New Fare comparison page with Sum-of-one-ways(Soow)*/}
          <Route
            path='searches/:leg/:cabinClass/:adultCount::childCount::infantCount/:searchId::tripCode/:fareCode'
            element={
              <FlightFareComparison
                apiBaseUrl={apiBaseUrl}
                currency={currency}
                currentSite={currentSite}
                exchangeRate={exchangeRate}
                gtmAuth={gtmAuth}
                gtmPreview={gtmPreview}
                isRtl={isRtl}
                locale={locale}
                localeParam={localeParam}
                nearestCityByIP={nearestCityByIP}
                translations={translations}
                user={user}
                wegoAnalyticsClientId={wegoAnalyticsClientId}
                wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
              />
            }
          />
          {/* Google flights hand off page yet to be decided to keep or remove it */}
          <Route
            path='handoff'
            element={
              <FlightExternalHandoff isRtl={isRtl} locale={locale} translations={translations} />
            }
          />

          {/* Booking page */}
          <Route
            path='searches/:leg/:cabinClass/:adultCount::childCount::infantCount/:searchId::tripCode/:fareCode/booking'
            element={
              <ContentErrorBoundary isErrorReload>
                <BookingPageProvider>
                  <FlightBooking
                    apiBaseUrl={apiBaseUrl}
                    currency={currency}
                    currentSite={currentSite}
                    exchangeRate={exchangeRate}
                    isRtl={isRtl}
                    locale={locale}
                    nearestCityByIP={nearestCityByIP}
                    translations={translations}
                    user={user}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </BookingPageProvider>
              </ContentErrorBoundary>
            }
          />

          {/* payment status verification page */}
          <Route path='booking/verify' element={<PaymentVerification />} />

          {/* flight confirmation page */}
          <Route
            path='booking/confirmation'
            element={
              <ContentErrorBoundary isErrorReload>
                <FlightBookingConfirmation
                  nearestCityByIP={nearestCityByIP}
                  wegoAnalyticsClientId={wegoAnalyticsClientId}
                  wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                />
              </ContentErrorBoundary>
            }
          />

          {/* Flight Booking History Details */}
          <Route
            path='booking/history/:bookingRef'
            element={
              <ContentErrorBoundary isErrorReload>
                <FlightBookingHistoryDetails />
              </ContentErrorBoundary>
            }
          />
        </Route>
      ));
    },
    [
      apiBaseUrl,
      availablePaymentMethods,
      campaignLogoImageUrl,
      currency,
      currentSite,
      exchangeRate,
      geolocationCoordinates,
      gtmAuth,
      gtmPreview,
      heroImageUrl,
      homepage,
      isRtl,
      locale,
      nearestCityByIP,
      translations,
      user,
      wegoAnalyticsClientId,
      wegoAnalyticsClientSessionId,
    ],
  );

  const hotelsPathRoutes = useCallback<
    (hotelsPaths: Array<string>, localeParam: string | undefined) => Array<React.ReactElement>
  >(
    (hotelsPaths: Array<string>, localeParam: string | undefined) => {
      return hotelsPaths.map((hotelsPath) => (
        <Route
          key={hotelsPath}
          path={hotelsPath}
          element={<HotelsPathRoute hotelsParam={hotelsPath} localeParam={localeParam} />}
        >
          <Route
            index
            element={
              <Home
                availablePaymentMethods={availablePaymentMethods || []}
                campaignLogoImageUrl={campaignLogoImageUrl}
                geolocationCoordinates={geolocationCoordinates}
                gtmAuth={gtmAuth}
                gtmPreview={gtmPreview}
                heroImageUrl={heroImageUrl}
                homepage={homepage}
                nearestCityByIP={nearestCityByIP}
                translations={translations}
                user={user}
                wegoAnalyticsClientId={wegoAnalyticsClientId}
                wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
              />
            }
          />

          {/* Legacy City Route */}
          {/* Todo: Remove after SEO, APP and Marketing side does their changes to guest param */}
          <Route
            path='searches/:cityCode/:checkInDate/:checkOutDate/:roomCount/:guestCount'
            element={<HotelSearchLayout geolocationCoordinates={geolocationCoordinates} />}
          >
            <Route
              index
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelSearchResult
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />

            {/* District Route */}
            <Route
              path='d:districtId'
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelSearchResult
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />

            <Route
              path=':hotelId'
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelDetail
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />
          </Route>

          {/* Legacy Region Route */}
          {/* Todo: Remove after SEO, APP and Marketing side does their changes to guest param */}
          <Route
            path='searches/q:regionId/:checkInDate/:checkOutDate/:roomCount/:guestCount'
            element={<HotelSearchLayout geolocationCoordinates={geolocationCoordinates} />}
          >
            <Route
              index
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelSearchResult
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />

            <Route
              path=':hotelId'
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelDetail
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />
          </Route>

          {/* City Route */}
          <Route
            path='searches/:cityCode/:checkInDate/:checkOutDate'
            element={<HotelSearchLayout geolocationCoordinates={geolocationCoordinates} />}
          >
            <Route
              index
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelSearchResult
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />

            {/* District Route */}
            <Route
              path='d:districtId'
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelSearchResult
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />

            <Route
              path=':hotelId'
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelDetail
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />
          </Route>

          {/* Region Route */}
          <Route
            path='searches/q:regionId/:checkInDate/:checkOutDate'
            element={<HotelSearchLayout geolocationCoordinates={geolocationCoordinates} />}
          >
            <Route
              index
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelSearchResult
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />

            <Route
              path=':hotelId'
              element={
                <ContentErrorBoundary isErrorReload>
                  <HotelDetail
                    gtmAuth={gtmAuth}
                    gtmPreview={gtmPreview}
                    localeParam={localeParam}
                    nearestCityByIP={nearestCityByIP}
                    wegoAnalyticsClientId={wegoAnalyticsClientId}
                    wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                  />
                </ContentErrorBoundary>
              }
            />
          </Route>

          {/* Booking Route */}
          <Route
            path='booking/checkout'
            element={
              <ContentErrorBoundary isErrorReload>
                <HotelBooking
                  gtmAuth={gtmAuth}
                  gtmPreview={gtmPreview}
                  localeParam={localeParam}
                  wegoAnalyticsClientId={wegoAnalyticsClientId}
                  wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                />
              </ContentErrorBoundary>
            }
          />

          <Route
            path='booking/confirm'
            element={
              <ContentErrorBoundary isErrorReload>
                <HotelBookingConfirmation
                  apiBaseUrl={apiBaseUrl}
                  gtmAuth={gtmAuth}
                  gtmPreview={gtmPreview}
                  wegoAnalyticsClientId={wegoAnalyticsClientId}
                  wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                />
              </ContentErrorBoundary>
            }
          />

          <Route
            path='booking/review'
            element={
              <ContentErrorBoundary isErrorReload>
                <HotelBookingReview />
              </ContentErrorBoundary>
            }
          />
        </Route>
      ));
    },
    [
      availablePaymentMethods,
      campaignLogoImageUrl,
      currency,
      exchangeRate,
      geolocationCoordinates,
      gtmAuth,
      gtmPreview,
      heroImageUrl,
      hotelMetaSearch,
      isRtl,
      locale,
      nearestCityByIP,
      priceOptions,
      translations,
      triggerSearchCounter,
      user,
      wegoAnalyticsClientId,
      wegoAnalyticsClientSessionId,
    ],
  );

  const preferencesPathRoutes = (
    preferencesPaths: Array<string>,
    localeParam: string | undefined,
  ) => {
    return preferencesPaths.map((path) => (
      <Route key={path} path={path} element={<AuthenticatedRoutes />}>
        <Route element={<PreferencesPathRoute localeParam={localeParam} />}>
          <Route index element={<Dashboard />}></Route>
          {isWegoShopcashEnabled(currentSite.countryCode) && !!shopCashUser && (
            <Route path='profile/wallet' element={<MyWallet />} />
          )}
          <Route
            path='profile/payment-methods'
            element={<PreferencesPreferredPaymentMethods />}
          ></Route>
          <Route path='help/contact-us' element={<ContactUs />}></Route>
          <Route
            path='trips/hotels'
            element={
              <HotelBookingHistory
                wegoAnalyticsClientId={wegoAnalyticsClientId}
                wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
              />
            }
          />
          <Route path='trips/flights' element={<FlightBookingHistory />} />
        </Route>
      </Route>
    ));
  };

  return (
    <GlobalContext.Provider
      value={{ userCentricCoreConfigs, webEngageAnonymousId, clientSideUserCentricCoreConfigs }}
    >
      <Translations locale={locale} />
      <IsRtl locale={locale} />
      <ExchangeRate apiBaseUrl={apiBaseUrl} currency={currency} shopCashUser={shopCashUser} />
      <ShopCashUser />
      <Suspense fallback={null}>
        <Routes>
          <Route
            path='/'
            element={
              <Layout
                currency={currency}
                currentSite={currentSite}
                domainBasedApiBaseUrl={domainBasedApiBaseUrl}
                exchangeRate={exchangeRate}
                isRtl={isRtl}
                locale={locale}
                shopCashUser={shopCashUser}
                translations={translations}
                user={user}
                wegoAnalyticsClientId={wegoAnalyticsClientId}
                wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
              />
            }
          >
            <Route
              index
              element={
                <Home
                  availablePaymentMethods={availablePaymentMethods || []}
                  campaignLogoImageUrl={campaignLogoImageUrl}
                  geolocationCoordinates={geolocationCoordinates}
                  gtmAuth={gtmAuth}
                  gtmPreview={gtmPreview}
                  heroImageUrl={heroImageUrl}
                  homepage={homepage}
                  nearestCityByIP={nearestCityByIP}
                  translations={translations}
                  user={user}
                  wegoAnalyticsClientId={wegoAnalyticsClientId}
                  wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                />
              }
            />

            {flightsPathRoutes(flightsPaths, undefined)}
            {hotelsPathRoutes(hotelsPaths, undefined)}
            {preferencesPathRoutes(preferencesPaths, undefined)}

            {locales.map((locale$) => (
              <Route key={locale$.code} path={locale$.code}>
                <Route
                  index
                  element={
                    <Home
                      availablePaymentMethods={availablePaymentMethods || []}
                      campaignLogoImageUrl={campaignLogoImageUrl}
                      geolocationCoordinates={geolocationCoordinates}
                      gtmAuth={gtmAuth}
                      gtmPreview={gtmPreview}
                      heroImageUrl={heroImageUrl}
                      homepage={homepage}
                      nearestCityByIP={nearestCityByIP}
                      translations={translations}
                      user={user}
                      wegoAnalyticsClientId={wegoAnalyticsClientId}
                      wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                    />
                  }
                />

                {flightsPathRoutes(flightsPaths, locale$.code)}
                {hotelsPathRoutes(hotelsPaths, locale$.code)}
                {preferencesPathRoutes(preferencesPaths, locale$.code)}
              </Route>
            ))}

            <Route path='*' element={<div>No such page.</div>} />
          </Route>
        </Routes>
      </Suspense>
    </GlobalContext.Provider>
  );
};

export default Routes$;
