import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import React from 'react';
import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';

import ContentError from './ContentError';

type ContentErrorBoundaryProps = Omit<
  ErrorBoundaryProps,
  'FallbackComponent' | 'fallbackRender' | 'fallback'
> & {
  children: React.ReactNode;
  isErrorReload?: boolean;
};

const ContentErrorBoundary = ({
  children,
  isErrorReload = false,
  onError,
  ...restProps
}: ContentErrorBoundaryProps) => {
  const onErrorBoundaryCallback = (error: Error, info: { componentStack: string }) => {
    Sentry.withScope((scope) => {
      scope.setContext('Content Error Boundary', {
        componentStack: info.componentStack,
        errorMessage: error.message,
      });
      scope.setLevel('error');
      Sentry.captureException(error);
    });

    datadogRum.addError(error, {
      errorType: 'Content Error Boundary',
      errorMessage: error?.message,
      componentStack: info?.componentStack,
    });

    onError?.(error, info);
  };

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <ContentError isErrorReload={isErrorReload} resetErrorBoundary={resetErrorBoundary} />
      )}
      onError={onErrorBoundaryCallback}
      {...restProps}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ContentErrorBoundary;
